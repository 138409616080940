import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { AppDispatch } from "store/store";
import { UseGenericMultiSelectProps } from "./types";
import { GenericMultiSelect } from "./";

export function useGenericMultiSelect<T, Y>({
    items,
    previouslySelectedIds,
    onSubmit,
    showNone = false,
    allowUnselect = false,
    isMultiple,
    allowItemClick = true,
    checkBoxLocation = "right",
    actionsBarOptions,
    searchSortOptions,
    allowNoSelection = false,
    loading = false
}: UseGenericMultiSelectProps<T, Y>) {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    // An ordered list of ids for items to be displayed
    const [displayOrder, setDisplayOrder] = useState<string[]>(
        items?.map((i) => i.id)
    );

    // Manage the which items are selected and which are visible
    const [selectedIdSet, setSelectedIdSet] = useState<Set<string>>(new Set());

    // If there are previously selected items, set them as selected
    useEffect(() => {
        if (previouslySelectedIds) {
            setSelectedIdSet(new Set(previouslySelectedIds));
        }
    }, [previouslySelectedIds]);

    const { visibleItems, anySelected, anyNewSelected, anyVisibleSelected } =
        useMemo(() => {
            if (!items || items?.length === 0) {
                return {
                    visibleItems: null,
                    anySelected: false,
                    anyNewSelected: false,
                    anyVisibleSelected: false
                };
            }

            const orderedItems =
                // If no display order
                !displayOrder ||
                // Or the display order is empty and search sort is not shown
                (displayOrder.length === 0 &&
                    !searchSortOptions?.showSearchSort)
                    ? // Show all items
                      items
                    : // Otherwise, show only the items in the displayOrder
                      displayOrder.map((id) => {
                          return items.find((i) => i.id === id);
                      });

            // If we should hide previously selected items, filter them out
            const filterPreviouslySelected =
                !allowUnselect && previouslySelectedIds?.length > 0;
            const visibleItems = filterPreviouslySelected
                ? orderedItems.filter(
                      (i) => !previouslySelectedIds?.includes(i.id)
                  )
                : orderedItems;

            // Return visible items and related visibilty/selection information
            return {
                visibleItems,
                anySelected: selectedIdSet.size > 0,
                anyNewSelected: items.some(
                    (i) =>
                        selectedIdSet.has(i.id) &&
                        !previouslySelectedIds?.includes(i.id)
                ),
                anyVisibleSelected: visibleItems?.some((i) =>
                    selectedIdSet.has(i?.id)
                )
            };
        }, [
            items,
            displayOrder,
            selectedIdSet,
            previouslySelectedIds,
            allowUnselect,
            searchSortOptions
        ]);

    const handleSelectedIdToggle = useCallback(
        (id: string) => {
            let clonedSet = new Set(selectedIdSet);

            if (clonedSet.has(id)) {
                clonedSet.delete(id);
            } else {
                if (!isMultiple) {
                    clonedSet.clear();
                }
                clonedSet.add(id);
            }

            setSelectedIdSet(clonedSet);
        },
        [selectedIdSet, isMultiple]
    );

    const handleSelect = useCallback(
        (id: string) => {
            handleSelectedIdToggle(id);
        },
        [handleSelectedIdToggle]
    );

    const handleSelectOrDeselectAll = useCallback(() => {
        let newSelectedItems: string[] = [];
        if (
            // If none are selected
            selectedIdSet.size === 0 ||
            ([...selectedIdSet].filter(
                // Or all the selected items were previously selected
                (id) => !previouslySelectedIds?.includes(id)
            ).length === 0 &&
                !allowUnselect) ||
            // Or none of the visible items are selected
            visibleItems.filter((i) => selectedIdSet.has(i.id)).length === 0
        ) {
            // Add all visible item ids to the selected set
            const newSelectedVisibleItems = items
                .map((i) => i.id)
                .filter((i) => {
                    return visibleItems.map((i) => i.id).includes(i);
                });
            newSelectedItems = [...selectedIdSet, ...newSelectedVisibleItems];
        } else if (allowUnselect || previouslySelectedIds?.length === 0) {
            // If unselection is allowed or there are no previously selected items, unselect all
            newSelectedItems = [];
        } else {
            // If unselection is not allowed, unselect all besides previously selected
            newSelectedItems = items
                .filter((i) => previouslySelectedIds?.includes(i.id))
                .map((i) => {
                    return i.id;
                });
        }

        setSelectedIdSet(new Set(newSelectedItems));
    }, [
        items,
        selectedIdSet,
        previouslySelectedIds,
        allowUnselect,
        visibleItems
    ]);

    const handleSubmit = useCallback(() => {
        if (!allowNoSelection && !anySelected) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("errors:no-items-selected")
                })
            );
            return;
        }

        onSubmit([...selectedIdSet]);
    }, [allowNoSelection, anySelected, selectedIdSet, onSubmit, dispatch, t]);

    const GenericMultiSelectComponent = useMemo(() => {
        return (
            <GenericMultiSelect<T, Y>
                items={items}
                selectedIdSet={selectedIdSet}
                previouslySelectedIds={previouslySelectedIds}
                handleSelect={handleSelect}
                handleSelectAll={handleSelectOrDeselectAll}
                allowUnselect={allowUnselect}
                allowItemClick={allowItemClick}
                isMultiple={isMultiple}
                showNone={showNone}
                checkBoxLocation={checkBoxLocation}
                actionsBarOptions={actionsBarOptions}
                searchSortOptions={searchSortOptions}
                loading={loading}
                displayOrder={displayOrder}
                setDisplayOrder={setDisplayOrder}
                visibleItems={visibleItems}
            />
        );
    }, [
        handleSelect,
        handleSelectOrDeselectAll,
        allowUnselect,
        isMultiple,
        showNone,
        actionsBarOptions,
        searchSortOptions,
        checkBoxLocation,
        allowItemClick,
        loading,
        items,
        selectedIdSet,
        previouslySelectedIds,
        displayOrder,
        setDisplayOrder,
        visibleItems
    ]);
    return {
        GenericMultiSelectComponent,
        handleSelectOrDeselectAll,
        anySelected,
        handleSubmit,
        anyNewSelected,
        visibleItems,
        anyVisibleSelected,
        selectedIdSet
    };
}
