import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePageHeader } from "hooks/usePageHeader";
import { Trans, useTranslation } from "react-i18next";
import { useStripeAccountInfo } from "hooks/useStripeAccountInfo";
import { useGetStripeConnectLink } from "hooks/useGetStripeConnectLink";
import { RecurringPassField } from "components/modal/GatedContentModal/RecurringPassField";
import { PricingModalTypes } from "components/modal/GatedContentModal";
import { StripeConnectCard } from "components/cards/StripeConnectButton";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { AppDispatch } from "store/store";
import { postEvents } from "store/events/thunks";
import rollbar from "helpers/rollbar";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import {
    buildPriceCreateObj,
    buildPricesArray
} from "components/modal/GatedContentModal/helpers";
import { v4 as uuidv4 } from "uuid";
import { displayAmount } from "helpers/stripe";
import { GatedContentPassFormProps } from "components/modal/GatedContentModal";
import {
    CreatorProductEntitlementsBindingModelDiscriminator,
    CreatorProductPricesBindingModel
} from "@switcherstudio/switcher-api-client";
import { RootState } from "store/reducers";
import { useGetStripeAccounts } from "hooks/useGetStripeAccounts";
import { exists } from "helpers/booleans";
import { sortByDate } from "helpers/time";
import { useCatalogData } from "hooks/useCatalogData";
import type { StringDictionary } from "types";
import { useCatalogAccessBanner } from "../hooks/useCatalogAccessBanner";
import { useBeforeUnload } from "hooks/useBeforeUnload";


export const CatalogSubscriptionPage: React.FC = () => {
    useCatalogAccessBanner();
    const { t } = useTranslation("subscription-page");
    const userInfo = useSelector((s: RootState) => s.user?.userInfo);

    const [createOrUpdate, setCreateOrUpdate] = useState<PricingModalTypes>(
        PricingModalTypes.Create
    );

    const dispatch = useDispatch<AppDispatch>();

    const [priceErrors, setPriceErrors] = useState<StringDictionary>({});

    const [values, setValues] = useState<GatedContentPassFormProps>({
        name: "Catalog Pass",
        description: "",
        selectedPassType: "recurring",
        recurringMonthlyPriceSelected: false,
        recurringAnnualPriceSelected: false,
        oneTimePrice: "",
        recurringMonthlyPrice: "10.00",
        recurringAnnualPrice: "100.00",
        recurringApplySubscriptionUpdatesNewOnly: true
    });

    usePageHeader({
        title: t("subscription-page:subscription-options"),
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("subscription-page:subscription-options")
        ],
        subTitle: (
            <Trans
                i18nKey={t("subscription-page:subscription-options-subtitle")}
            />
        )
    });

    const { details, gatedContentStatus, loading } = useStripeAccountInfo({
        expandDetails: true
    });

    const { accounts } = useGetStripeAccounts();

    const { isSetup } = useGetStripeConnectLink();

    /** Get the catalog on page load */
    const { catalogData: catalog, loading: catalogLoading } = useCatalogData({
        projectId: userInfo?.ProjectId
    });

    /** What on EARTH is going on here with all these API calls?
     *
     * Well, there are PRODUCTS
     * and there are PRODUCT PRICES.
     * There are also ENTITLEMENTS.
     * (Created in that order)
     */

    /** PRODUCTS can be posted or put.
     * Only one product is ever associated to the catalog
     * and it cannot be used on other entities (such as collections).
     * The only PRODUCT property that could change is the name, and the catalog pass
     * is hard-coded as "Catalog Pass." No changing this once it's made.
     */
    const { dispatchApiRequest: postProduct } = useSwitcherClient(
        (client) => client.creatorProducts_Create
    );

    /** PRODUCT PRICES can be posted or put (created or updated). */
    const { dispatchApiRequest: postProductPrices } = useSwitcherClient(
        (client) => client.creatorProductPrices_Create
    );

    const { dispatchApiRequest: putProductPrices } = useSwitcherClient(
        (client) => client.creatorProductPrices_Update
    );

    /** ENTITLEMENTs can be posted or deleted.
     * For the catalog, once the ENTITLEMENT is created, it can't be deleted.
     * It is only deactivated.
     */
    const { dispatchApiRequest: postEntitlement } = useSwitcherClient(
        (client) => client.creatorProductEntitlements_Create
    );

    /** In one api call, the catalog entitlement and all associated prices are retrieved with the CatalogId */
    const {
        dispatchApiRequest: getEntitlementAndPrices,
        data: entitlementAndPrices
    } = useSwitcherClient(
        (client) => client.creatorProductEntitlements_GetByCatalogId,
        {
            requestImmediately: true,
            args: [catalog?.Details?.Id, true]
        }
    );

    /** The existent, unaltered product/pass for the catalog. */
    const oldProduct = entitlementAndPrices?.ProductEntitlements[0]?.Product;

    useEffect(() => {
        if (exists(oldProduct)) {
            setCreateOrUpdate(PricingModalTypes.Update);
        }
    }, [oldProduct]);

    const oldRecurringMonthlyPrice = useMemo(() => {
        const filteredOldMonthlyPrices = oldProduct?.Prices?.filter(
            (p) => p.RecurringInterval === "month"
        );
        filteredOldMonthlyPrices?.sort((a, b) =>
            sortByDate(a.InsertedAt, b.InsertedAt, { descending: true })
        );
        return filteredOldMonthlyPrices?.[0];
    }, [oldProduct]);

    const oldRecurringAnnualPrice = useMemo(() => {
        const filteredOldYearlyPrices = oldProduct?.Prices?.filter(
            (p) => p.RecurringInterval === "year"
        );
        filteredOldYearlyPrices?.sort((a, b) =>
            sortByDate(a.InsertedAt, b.InsertedAt, { descending: true })
        );
        return filteredOldYearlyPrices?.[0];
    }, [oldProduct]);

    const onSubmit = useCallback(() => {
        getEntitlementAndPrices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = useCallback(
        async ({
            name,
            recurringMonthlyPriceSelected,
            recurringAnnualPriceSelected,
            recurringMonthlyPrice,
            recurringAnnualPrice,
            recurringApplySubscriptionUpdatesNewOnly
        }: GatedContentPassFormProps) => {
            //check if values have been changed
            const recurringPassHasNoChanges =
                oldRecurringMonthlyPrice?.Active ===
                    recurringMonthlyPriceSelected &&
                oldRecurringMonthlyPrice?.Amount ===
                    parseFloat(recurringMonthlyPrice) * 100 &&
                oldRecurringAnnualPrice?.Active ===
                    recurringAnnualPriceSelected &&
                oldRecurringAnnualPrice?.Amount ===
                    parseFloat(recurringAnnualPrice) * 100;

            if (Object.keys(priceErrors).length > 0) {
                return;
            }

            if (createOrUpdate === PricingModalTypes.Update) {
                const subscriberCount = oldProduct?.ActiveSubscriptions;
                if (subscriberCount !== null && subscriberCount > 0) {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t(
                                "gated-content-modal:errors:pass-with-subscribers-error"
                            )
                        })
                    );
                    onSubmit();
                    return;
                }

                if (recurringPassHasNoChanges) {
                    dispatch(
                        addNotification({
                            type: NotificationType.Info,
                            message: t(
                                "gated-content-modal:messages:pricing-update-success-catalog"
                            )
                        })
                    );
                    onSubmit();
                    return;
                }

                await postEntitlement([
                    {
                        ProductEntitlements: [
                            {
                                ProductId: oldProduct.Id,
                                Discriminator:
                                    CreatorProductEntitlementsBindingModelDiscriminator._3,
                                CatalogId: catalog?.Details?.Id
                            }
                        ]
                    }
                ]);
            }
            if (createOrUpdate === PricingModalTypes.Create) {
                const getPrices = (
                    productId: string
                ): CreatorProductPricesBindingModel[] => {
                    const prices = [];

                    if (recurringMonthlyPriceSelected) {
                        prices.push(
                            buildPriceCreateObj(
                                name,
                                productId,
                                recurringMonthlyPrice,
                                true,
                                "month"
                            )
                        );
                    }

                    if (recurringAnnualPriceSelected) {
                        prices.push(
                            buildPriceCreateObj(
                                name,
                                productId,
                                recurringAnnualPrice,
                                true,
                                "year"
                            )
                        );
                    }

                    return prices;
                };

                try {
                    const res = await postProduct([
                        accounts[0]?.Id,
                        {
                            Products: [
                                {
                                    Id: uuidv4(),
                                    Name: "Catalog Pass",
                                    StripeAccountId: accounts[0]?.Id,
                                    IsActive: true
                                }
                            ]
                        }
                    ]);

                    await postProductPrices([
                        accounts?.[0]?.Id,
                        res?.Products?.[0]?.Id,
                        {
                            Prices: getPrices(res?.Products?.[0]?.Id)
                        }
                    ]);

                    await postEntitlement([
                        {
                            ProductEntitlements: [
                                {
                                    ProductId: res?.Products[0]?.Id,
                                    Discriminator:
                                        CreatorProductEntitlementsBindingModelDiscriminator._3,
                                    CatalogId: catalog?.Details?.Id
                                }
                            ]
                        }
                    ]);

                    dispatch(postEvents({ "created-pass": true }));
                    dispatch(
                        addNotification({
                            type: NotificationType.Success,
                            message: t(
                                "gated-content-modal:messages:pricing-create-success"
                            )
                        })
                    );
                } catch (e) {
                    rollbar.error("Error creating catalog pass", e);
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t(
                                "gated-content-modal:errors:pricing-create-error"
                            )
                        })
                    );
                } finally {
                    onSubmit();
                }
            } else {
                const pricesArray = buildPricesArray({
                    recurringMonthlyPriceSelected,
                    recurringAnnualPriceSelected,
                    recurringMonthlyPrice,
                    recurringAnnualPrice,
                    oneTimePrice: null,
                    oldProduct,
                    oldRecurringMonthlyPrice,
                    oldRecurringAnnualPrice,
                    oldOneTimePrice: null,
                    name: "Catalog Pass",
                    selectedPassType: "recurring"
                });

                try {
                    await putProductPrices([
                        accounts[0]?.Id,
                        oldProduct?.Id,
                        {
                            Prices: pricesArray,
                            UpdateExistingCustomersToPrice:
                                !recurringApplySubscriptionUpdatesNewOnly
                        }
                    ]);

                    dispatch(
                        addNotification({
                            type: NotificationType.Success,
                            message: t(
                                "gated-content-modal:messages:pricing-update-success-catalog"
                            )
                        })
                    );
                } catch (e) {
                    rollbar.error("Error updating catalog pass", e);
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t(
                                "gated-content-modal:errors:pricing-update-error"
                            )
                        })
                    );
                } finally {
                    onSubmit();
                }
            }
        },
        [
            accounts,
            catalog?.Details?.Id,
            oldProduct,
            oldRecurringMonthlyPrice,
            oldRecurringAnnualPrice,
            createOrUpdate,
            priceErrors,
            postProduct,
            onSubmit,
            postProductPrices,
            postEntitlement,
            putProductPrices,
            dispatch,
            t
        ]
    );

    const validateFields = useCallback(
        ({
            recurringMonthlyPriceSelected,
            recurringAnnualPriceSelected,
            recurringAnnualPrice,
            recurringMonthlyPrice
        }: GatedContentPassFormProps) => {
            let errors: StringDictionary = {};
            const validatePrice = (id: string, price: string) => {
                const baseError = t(
                    "gated-content-modal:errors:price-min-error"
                );
                if (!price) {
                    errors[id] = t("gated-content-modal:errors:price-error");
                }

                const minPrices = {
                    recurringAnnualPrice: import.meta.env
                        .VITE_MINIMUM_ANNUAL_PRICE,
                    recurringMonthlyPrice: import.meta.env
                        .VITE_MINIMUM_MONTHLY_PRICE,
                    oneTimePrice: import.meta.env.VITE_MINIMUM_ONE_TIME_PRICE
                };

                if (parseFloat(price) < parseFloat(minPrices[id])) {
                    errors[id] = `${baseError} $${minPrices[id]}`;
                }
            };

            if (recurringMonthlyPriceSelected) {
                validatePrice("recurringMonthlyPrice", recurringMonthlyPrice);
            }
            if (recurringAnnualPriceSelected) {
                validatePrice("recurringAnnualPrice", recurringAnnualPrice);
            }

            setPriceErrors(errors);
            return errors;
        },
        [t]
    );

    const handlePriceChange = useCallback(
        (key: string, val: any) => {
            setValues({ ...values, [key]: val });
            validateFields({ ...values, [key]: val });
        },
        [values, validateFields, setValues]
    );

    useEffect(() => {
        if (createOrUpdate === PricingModalTypes.Update) {
            setValues({
                ...values,
                ...(oldRecurringMonthlyPrice && {
                    recurringMonthlyPriceSelected:
                        oldRecurringMonthlyPrice?.Active,
                    recurringMonthlyPrice: displayAmount(
                        oldRecurringMonthlyPrice?.Amount,
                        {
                            signed: false,
                            compact: false,
                            roundUp: false,
                            useGrouping: false
                        }
                    )
                }),
                ...(oldRecurringAnnualPrice && {
                    recurringAnnualPriceSelected:
                        oldRecurringAnnualPrice?.Active,
                    recurringAnnualPrice: displayAmount(
                        oldRecurringAnnualPrice?.Amount,
                        {
                            signed: false,
                            compact: false,
                            roundUp: false,
                            useGrouping: false
                        }
                    )
                }),
                ...(!oldRecurringMonthlyPrice?.Active && {
                    recurringMonthlyPriceSelected: false
                }),
                ...(!oldRecurringAnnualPrice?.Active && {
                    recurringAnnualPriceSelected: false
                }),
                recurringApplySubscriptionUpdatesNewOnly:
                    values.recurringApplySubscriptionUpdatesNewOnly
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        oldProduct,
        oldRecurringAnnualPrice,
        oldRecurringMonthlyPrice,
        createOrUpdate
    ]);

    const recurringPassHasNoChanges = useMemo(() => {
        const monthlyActiveMatch =
            oldRecurringMonthlyPrice?.Active ===
            values?.recurringMonthlyPriceSelected;
        const monthlyAmountMatch =
            oldRecurringMonthlyPrice?.Amount ===
            parseFloat(values?.recurringMonthlyPrice) * 100;
        const annualActiveMatch =
            oldRecurringAnnualPrice?.Active ===
            values?.recurringAnnualPriceSelected;
        const annualAmountMatch =
            oldRecurringAnnualPrice?.Amount ===
            parseFloat(values.recurringAnnualPrice) * 100;

        const monthlyNotActiveAndMatch =
            !values?.recurringMonthlyPriceSelected && monthlyActiveMatch;
        const annualNotActiveAndMatch =
            !values?.recurringAnnualPriceSelected && annualActiveMatch;

        return (
            (monthlyNotActiveAndMatch && annualNotActiveAndMatch) ||
            (monthlyActiveMatch &&
                monthlyAmountMatch &&
                annualActiveMatch &&
                annualAmountMatch)
        );
    }, [values, oldRecurringAnnualPrice, oldRecurringMonthlyPrice]);

    useBeforeUnload(!recurringPassHasNoChanges, null, true);

    return (
        <div>
            {!loading && !catalogLoading && (
                <div>
                    <StripeConnectCard
                        details={details}
                        gatedContentStatus={gatedContentStatus}
                        variant="Catalog"
                    />
                    {isSetup && (
                        <RecurringPassField
                            errors={priceErrors}
                            type={createOrUpdate}
                            values={values}
                            variant="catalog"
                            onChange={handlePriceChange}
                            onSubmit={() => handleSubmit(values)}
                            submitDisabled={recurringPassHasNoChanges}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
