import { LoaderFunction, redirect } from "react-router";
import { RouteMetaData } from "./types";

export const routeLoader = (state: RouteMetaData): LoaderFunction => {
    return () => {
        return state;
    };
};

export const redirectTo = (path: string): LoaderFunction => {
    return ({ request, params }) => {
        Object.keys(params).forEach((key) => {
            path = path.replace(`:${key}`, params[key]);
        });

        const url = new URL(request.url);
        const queryString = url.search;
        return redirect(`${path}${queryString}`);
    };
};

export const windowOpen = (path: string): LoaderFunction => {
    return () => {
        window.open(path, "_blank");
        return redirect("/");
    };
};
