import { Dispatch, SetStateAction, useCallback } from "react";
import { ModalBase } from "components/modal/ModalBase";
import styles from "./index.module.scss";
import CloseIcon from "assets/icons/close.svg?react";
import { useTranslation } from "react-i18next";
import { ComponentItem } from "components/generic-multiselect/types";
import { useGenericMultiSelect } from "../../generic-multiselect/useGenericMultiSelect";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";

export interface GenericMultiSelectModalProps<T> {
    isMultiple: boolean; //should the user be able to select multiple items?
    onSubmit: (ids: string[]) => void; //callback function to be called on modal's onSubmit. returns list of selected ids
    isOpen: boolean; //state of modal's open vs closed
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    previouslySelectedIds: string[]; //if not applicable, send empty array
    allowUnselect?: boolean; // allows unselection of previous items
    titleText: string; //title of modal
    buttonText: string; //text within submit button
    items?: ComponentItem<T>[]; //list of component item objects
    /** Prevents error on selecting no items */
    allowNoSelection?: boolean;
    /** Submit button not clickable if no items selected. Independent of allowNoSelection. */
    preventDismiss?: boolean;
    submitHandlesClose?: boolean;
    showNone?: boolean;
    /** Indicates to modal to show loading state */
    loading?: boolean;
    emptyStateMessage?: JSX.Element;
}

export function GenericMultiSelectModal<T>({
    isMultiple,
    onSubmit,
    isOpen,
    setIsOpen,
    previouslySelectedIds = [],
    allowUnselect = false,
    titleText,
    buttonText,
    items,
    allowNoSelection = false,
    preventDismiss = false,
    submitHandlesClose = false,
    showNone = false,
    loading = false,
    emptyStateMessage
}: GenericMultiSelectModalProps<T>) {
    const { t } = useTranslation();
    const { isInBrowser } = useSwitcherSdk();
    const {
        GenericMultiSelectComponent,
        handleSubmit,
        handleSelectOrDeselectAll,
        anySelected
    } = useGenericMultiSelect<T, T>({
        isMultiple,
        previouslySelectedIds,
        allowUnselect,
        onSubmit,
        items,
        allowNoSelection,
        showNone,
        loading
    });

    const close = useCallback(() => setIsOpen(false), [setIsOpen]);

    const handleSubmitLocal = useCallback(() => {
        handleSubmit && handleSubmit();
        submitHandlesClose && setIsOpen(false);
    }, [submitHandlesClose, handleSubmit, setIsOpen]);

    if (items === null) return null;
    return (
        <ModalBase
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            preventDismiss={preventDismiss}
            stylesOverride={{
                padding: "0",
                maxHeight: "unset"
            }}
        >
            <div
                className={`${styles["multi-select-container"]} ${
                    !isInBrowser ? styles["in-app"] : ""
                }`}
            >
                <div className={styles["header"]}>
                    <h5>{titleText}</h5>
                    <button
                        type="button"
                        className={`btn ${styles["button"]}`}
                        onClick={close}
                    >
                        <CloseIcon />
                    </button>
                </div>

                <hr />

                <div className={styles["content"]}>
                    {items?.length ? (
                        GenericMultiSelectComponent
                    ) : (
                        <div className={styles["empty-state"]}>
                            {emptyStateMessage}
                        </div>
                    )}
                </div>

                <hr />

                <div className={styles["footer"]}>
                    <button type="button" className="btn" onClick={close}>
                        {t("buttons:cancel")}
                    </button>

                    {items?.length ? (
                        <span>
                            {isMultiple && (
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={() => handleSelectOrDeselectAll()}
                                >
                                    {!anySelected
                                        ? t("buttons:select-all")
                                        : t("buttons:deselect-all")}
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleSubmitLocal}
                            >
                                {buttonText}
                            </button>
                        </span>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </ModalBase>
    );
}
