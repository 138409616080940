import { routeLoader } from "router/loaders";
import { PlayersPage } from "views/page-content/players";
import { AppearancePage } from "views/page-content/video-player/video-player-settings/AppearancePage";
import { InteractiveLinksPage } from "views/page-content/video-player/video-player-settings/InteractiveLinksPage";
import { PlayerGatedContentPage } from "views/page-content/video-player/video-player-settings/PlayerGatedContentPage";
import { PlayerStatusPage } from "views/page-content/video-player/video-player-settings/PlayerStatusPage";
import { PlaylistPage } from "views/page-content/video-player/video-player-settings/PlaylistPage";
import { VideoPlayerPage } from "views/page-content/video-player/VideoPlayerPage";

const playerRouteData = {
    badgeConfig: {
        claimCheck: false,
        claim: "videoplayer",
        label: "preview",
        class: "badge-preview"
    },
    upgradeConfig: {
        claimCheck: false,
        claim: "videoplayer",
        label: "upgrade-business"
    }
};
export const PlayerRoutes = [
    {
        path: "switcher-players",
        element: <PlayersPage />
    },
    {
        // I feel like this ought to redirect to switcher-players
        path: "switcher-player",
        element: <VideoPlayerPage />
    },
    {
        path: "switcher-player/:videoPlayerId",
        element: <VideoPlayerPage />,
        loader: routeLoader({
            data: playerRouteData
        })
    },
    {
        path: "switcher-player/:videoPlayerId/gated-content",
        element: <PlayerGatedContentPage />,
        loader: routeLoader({
            data: playerRouteData
        })
    },
    {
        path: "switcher-player/:videoPlayerId/playlist",
        element: <PlaylistPage />,
        loader: routeLoader({
            data: playerRouteData
        })
    },
    {
        path: "switcher-player/:videoPlayerId/player-status",
        element: <PlayerStatusPage />,
        loader: routeLoader({
            data: {
                ...playerRouteData,
                backNavigation: "switcher-player/:videoPlayerId",
                backNavigationText:
                    "video-player-page:sub-page-back-navigation-text"
            }
        })
    },
    {
        path: "switcher-player/:videoPlayerId/appearance",
        element: <AppearancePage />,
        loader: routeLoader({
            data: {
                ...playerRouteData,
                backNavigation: "switcher-player/:videoPlayerId",
                backNavigationText:
                    "video-player-page:sub-page-back-navigation-text"
            }
        })
    },
    {
        path: "switcher-player/:videoPlayerId/interactive-links",
        element: <InteractiveLinksPage />,
        loader: routeLoader({
            data: {
                ...playerRouteData,
                backNavigation: "switcher-player/:videoPlayerId",
                backNavigationText:
                    "video-player-page:sub-page-back-navigation-text"
            }
        })
    }
];
