import styles from "./index.module.scss";
import { StyledCheckboxOrRadio } from "components/inputs/checkbox/StyledCheckboxOrRadio";
import classnames from "classnames/bind";
const cx = classnames.bind(styles);

export function ItemsList({
    items,
    allowItemClick,
    checkBoxLocation,
    isMultiple,
    handleSelectLocal,
    selectedIdSet,
    previouslySelectedIds,
    allowUnselect
}) {
    return (
        <>
            {items?.map((item) => {
                if (!item || !item.id || !item.component) return null;
                const { id, component } = item;
                const wasPreviouslySelected =
                    !allowUnselect &&
                    isMultiple &&
                    previouslySelectedIds.includes(id);
                if (wasPreviouslySelected) return false;
                const isSelected = selectedIdSet.has(id);

                return (
                    <div
                        className={cx("ms-card", {
                            "previously-selected": wasPreviouslySelected,
                            "checkbox-left": checkBoxLocation === "left",
                            "allow-item-click": allowItemClick
                        })}
                        key={id}
                        onClick={() =>
                            allowItemClick && handleSelectLocal(id, isSelected)
                        }
                    >
                        <div className={cx("ms-card-component")}>
                            {component}
                        </div>
                        <StyledCheckboxOrRadio
                            type={isMultiple ? "checkbox" : "radio"}
                            checked={isSelected}
                            onChange={() => handleSelectLocal(id, isSelected)}
                            disabled={wasPreviouslySelected}
                        />
                    </div>
                );
            })}
        </>
    );
}
